




























import { Component, Vue } from 'vue-property-decorator'
import { UserInfo } from '@/types/developer'

@Component({
  name: 'Detail',
  filters: {
    filtersStatus (value: string) {
      return value === '0'
        ? '未发送'
        : value === '1'
          ? '已发送'
          : ''
    }
  }
})
export default class Detail extends Vue {
  private info = {}
  private userNameList: string[] = []
  created () {
    this.getData()
  }

  getData () {
    this.$axios.get(this.$apis.message.selectMessageByMessageId, {
      messageId: this.$route.params.id
    }).then((res) => {
      this.info = res
      if (res.messageReceiverList && res.messageReceiverList.length > 0) {
        res.messageReceiverList.map((item: {receiverName: string}) => {
          item.receiverName && this.userNameList.push(item.receiverName)
        })
      }
    })
  }
}
